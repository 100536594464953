import { useState, useEffect } from 'react';
import { AppBar as AppBarMUI, Box, Grid, Hidden, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import {
	Brightness4Rounded as DarkModeIcon,
	Brightness7Rounded as LightModeIcon,
	Menu as MenuIcon,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { useStyles } from './AppBar.styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ROOT_PATH } from '../../constants/paths';
import { THEME } from '../../constants/theme';
import clsx from 'clsx';
import { ReactComponent as Logo } from '../../assets/kch_logo.svg';

interface AppBarProps {
	toggleTheme: () => void;
	toggleDrawer: () => void;
}

export const AppBar = ({ toggleTheme, toggleDrawer }: AppBarProps) => {
	const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
	const classes = useStyles();
	const { pathname } = useLocation();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		setIsDarkMode(theme.palette.type === THEME.DARK);
	}, [theme]);

	return (
		<AppBarMUI className={classes.appBar} elevation={3}>
			<Toolbar disableGutters>
				<Hidden mdUp={pathname !== ROOT_PATH}>
					<IconButton onClick={toggleDrawer}>
						<MenuIcon />
					</IconButton>
				</Hidden>
				{pathname !== ROOT_PATH ? (
					<Grid container item justifyContent={smDown ? 'center' : 'flex-start'}>
						<Link to={ROOT_PATH} className={clsx(classes.link, { [classes.titleLink]: !smDown })}>
							<Hidden smDown>
								<Logo width={48} height={48} />
							</Hidden>
							<Typography variant="h5" color="textPrimary" className={classes.title}>
								Ústav chémie a environmentálnych vied
							</Typography>
						</Link>
					</Grid>
				) : (
					<Box className={classes.flexGrow} />
				)}
				<Tooltip title={isDarkMode ? 'Prepnúť na svetlú tému' : 'Prepnúť na tmavú tému'}>
					<IconButton onClick={toggleTheme}>{isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}</IconButton>
				</Tooltip>
			</Toolbar>
		</AppBarMUI>
	);
};
