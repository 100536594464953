import { combineReducers } from 'redux';
import { Contentful } from '../model';
import * as contentfulReducer from './contentful';

export interface RootState {
	contentful: Contentful;
}

const rootReducer = combineReducers({
	...contentfulReducer,
});

export default rootReducer;
