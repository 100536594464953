import PublikacnaCinnost from './PublikacnaCinnost';
import { pageSettings } from '../../index';
import { PUBLIKACNA_CINNOST_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: PUBLIKACNA_CINNOST_PATH,
	name: 'Publikačná činnosť',
	component: PublikacnaCinnost,
	contents: [],
};

export default settings;
