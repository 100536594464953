import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	summary: {
		display: 'block',
		'& > h6:first-of-type': {
			marginTop: 0,
		},
	},
	details: {
		display: 'block',
		'& > h6:first-of-type': {
			marginTop: 0,
		},
	},
}));
