import { List, ListItem, Collapse, ListItemText, ListItemIcon } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useStyles } from './NavItemWithSubpages.styles';
import { useState, useEffect } from 'react';
import { pageSettings } from '../../../pages';
import { NavItem } from '../NavItem';
import clsx from 'clsx';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTheme } from '@material-ui/core/styles';
import { THEME } from '../../../constants/theme';

interface NavItemWithSubpagesProps {
	name: string;
	path: string;
	subPages: pageSettings[];
	toggleDrawer?: () => void;
	padded?: boolean;
	redirect?: string;
}

export const NavItemWithSubpages = (Props: NavItemWithSubpagesProps) => {
	const { name, path, subPages, toggleDrawer, padded, redirect } = Props;
	const theme = useTheme();
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);
	const { pathname } = useLocation();
	const history = useHistory();
	const [timeout, setTiming] = useState<TransitionProps['timeout'] | 'auto'>(0);

	useEffect(() => {
		const isCurrent = pathname.startsWith(path);
		setOpen(isCurrent);
		if (!isCurrent) setTiming('auto');
	}, [path, pathname]);

	return (
		<List disablePadding>
			<ListItem
				button
				onClick={() => {
					if (redirect) history.push(redirect);
					setOpen(!open);
				}}
				classes={{
					root: clsx(classes.rootListItem, {
						[classes.hoveredDark]: theme.palette.type === THEME.DARK,
						[classes.hoveredLight]: theme.palette.type === THEME.LIGHT,
						[classes.padded]: padded,
					}),
				}}
			>
				<ListItemText primary={name} />
				<ListItemIcon classes={{ root: classes.listItemIcon }}>
					<KeyboardArrowRight
						className={clsx({
							[classes.dropdown]: timeout,
							[classes.dropdownOpen]: open,
							[classes.dropdownClosed]: !open,
						})}
					/>
				</ListItemIcon>
			</ListItem>
			<Collapse
				in={open}
				timeout={timeout}
				onEntered={() => {
					if (timeout === 0) setTiming('auto');
				}}
				mountOnEnter
			>
				{subPages.map((subPage: pageSettings) => {
					if (subPage.subPages) {
						return (
							<NavItemWithSubpages
								key={subPage.name}
								name={subPage.name}
								path={subPage.path}
								subPages={subPage.subPages}
								redirect={subPage.redirect}
								toggleDrawer={toggleDrawer}
								padded
							/>
						);
					} else if (subPage.component && subPage.path) {
						return (
							<NavItem key={subPage.name} path={subPage.path} name={subPage.name} toggleDrawer={toggleDrawer} padded />
						);
					} else {
						return null;
					}
				})}
			</Collapse>
		</List>
	);
};
