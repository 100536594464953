import { Redirect, Route, Switch } from 'react-router-dom';
import Domov from './pages/Domov';
import pages, { pageSettings } from './pages';
import { AKTUALITY_PATH } from './constants/paths';

export const Routes = () => {
	const buildRoutes = (routeElements: JSX.Element[], pages: pageSettings[]) => {
		pages.forEach((page: pageSettings) => {
			if (page.component) {
				routeElements.push(<Route exact path={page.path} component={page.component} key={page.path} />);
			}
			if (page.subPages) {
				buildRoutes(routeElements, page.subPages);
			}
		});
	};

	const routeElements = [<Route exact path={'/'} key="Route-Domov" component={Domov.component} />];
	buildRoutes(routeElements, pages);
	routeElements.push(<Redirect to={AKTUALITY_PATH} key={'UNKNOWN_PATH'} />);

	return <Switch>{routeElements.map((e) => e)}</Switch>;
};
