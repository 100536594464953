import { Fab, Zoom } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import { useStyles } from './ScrollToTop.styles';
import { RefObject } from 'react';

interface ScrollToTopProps {
	visible: boolean;
	topAnchor: RefObject<HTMLInputElement>;
}

export const ScrollToTop = ({ visible, topAnchor }: ScrollToTopProps) => {
	const classes = useStyles();

	const handleScrollToTop = () => {
		if (topAnchor && topAnchor.current) {
			topAnchor.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	return (
		<Zoom in={visible}>
			<Fab
				color="secondary"
				size="medium"
				onClick={handleScrollToTop}
				classes={{ root: classes.fabShadow }}
				className={classes.backToTop}
			>
				<KeyboardArrowUp fontSize="large" />
			</Fab>
		</Zoom>
	);
};
