import { useStyles } from './LoadableImage.styles';
import { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
interface LoadableImageProps {
	src: string;
	alt: string;
	width: number | string;
	height?: number | string;
	shadow?: boolean;
}

export const LoadableImage = ({ src, alt, width, height, shadow }: LoadableImageProps) => {
	const classes = useStyles();
	const [loaded, setLoaded] = useState<boolean>(false);

	return (
		<>
			<img
				src={src}
				alt={`${alt}(loader)`}
				width={width}
				height={height || width}
				onLoad={() => setLoaded(true)}
				className={classes.hidden}
			/>
			{loaded ? (
				<img
					src={src}
					alt={alt}
					width={width}
					height={height || width}
					className={clsx({ [classes.imgShadow]: shadow })}
				/>
			) : (
				<Skeleton variant="rect" width={width} height={height || width} />
			)}
		</>
	);
};
