import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { THEME } from './constants/theme';

declare module '@material-ui/core/styles/createPalette' {
	interface Palette {
		navigation: {
			background: string;
		};
	}
	interface PaletteOptions {
		navigation?: {
			background?: string;
		};
	}
}

const paletteColors = {
	primary: {
		main: '#42b8dd',
		light: '#67c6e3',
		dark: '#2e809a',
		contrastText: '#fff',
	},
	secondary: {
		main: '#92d247',
		light: '#a7db6b',
		dark: '#669331',
		contrastText: '#fff',
	},
};

const themeOptions = {
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: { overflow: 'hidden' },
				a: {
					textDecoration: 'none',
					color: 'inherit',
				},
				b: {
					fontWeight: 550,
				},
				strong: {
					fontWeight: 550,
				},
			},
		},
	},
	typography: {
		h1: {
			fontSize: '2.5rem',
			fontWeight: 400,
			letterSpacing: 0,
			margin: '16px 0',
		},
		h2: {
			fontSize: '1.875rem',
			fontWeight: 400,
			letterSpacing: 0,
			margin: '40px 0 16px',
		},
		h3: {
			fontSize: '1.5rem',
			fontWeight: 400,
			letterSpacing: 0,
			margin: '32px 0 16px',
		},
		h4: {
			fontSize: '1.4rem',
			fontWeight: 400,
			letterSpacing: 0,
			margin: '24px 0 16px',
		},
		h5: {
			fontSize: '1.3rem',
			fontWeight: 500,
			letterSpacing: 0,
			margin: '24px 0 16px',
		},
		h6: {
			fontSize: '1.25rem',
			fontWeight: 500,
			letterSpacing: 0,
			margin: '24px 0 16px',
		},
	},
};

export const lightTheme = responsiveFontSizes(
	createTheme({
		palette: {
			type: THEME.LIGHT,
			navigation: {
				background: '#f4f4f4',
			},
			...paletteColors,
		},
		...themeOptions,
	}),
	{
		disableAlign: true,
	}
);

export const darkTheme = responsiveFontSizes(
	createTheme({
		palette: {
			type: THEME.DARK,
			background: {
				default: '#212121',
				paper: '#333',
			},
			navigation: {
				background: '#181818',
			},
			...paletteColors,
		},
		...themeOptions,
	}),
	{
		disableAlign: true,
	}
);
