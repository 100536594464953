import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		position: 'relative',
		padding: theme.spacing(2),
	},
	fullW: {
		width: '100%',
	},
	thirdW: {
		width: '30%',
	},
	title: {
		margin: 0,
	},
	copyLink: {
		position: 'absolute',
		top: 6,
		right: 6,
	},
	successCopyIcon: {
		color: theme.palette.success.main,
		marginLeft: theme.spacing(0.5),
	},
	highlighted: {
		animation: `$highlighted 6s ${theme.transitions.easing.easeOut} both`,
	},
	'@keyframes highlighted': {
		'0%': {
			boxShadow: `0px 0px 32px ${theme.palette.primary.main}`,
		},
		'100%': {
			boxShadow: theme.shadows[1],
		},
	},
}));
