import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	absolute: {
		position: 'absolute',
	},
	l: {
		left: 6,
	},
	t: {
		top: 6,
	},
	r: {
		right: 6,
	},
	b: {
		bottom: 6,
	},
	successCopyIcon: {
		color: theme.palette.success.main,
		marginLeft: theme.spacing(0.5),
	},
}));
