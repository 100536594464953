import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	backToTop: {
		position: 'absolute',
		zIndex: 10,
		bottom: theme.spacing(3),
		right: theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			right: theme.spacing(3),
		},
	},
	fabShadow: {
		boxShadow: theme.shadows[6],
	},
}));
