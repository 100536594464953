import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
	},
	titleLink: {
		paddingLeft: theme.spacing(1.5),
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: theme.typography.fontWeightMedium,
		margin: 0,
	},
	flexGrow: {
		flexGrow: 1,
	},
	link: {
		lineHeight: 0,
		display: 'flex',
		alignItems: 'center',
		'& > *:last-child': {
			marginLeft: theme.spacing(2),
		},
	},
}));
