import { FunctionComponent, useState } from 'react';
import { Box, Fade, IconButton, Tooltip } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { useStyles } from './FadingCopyButton.styles';
import clsx from 'clsx';

interface FadingCopyButtonProps {
	show: boolean;
	title: string;
	message: string;
	l?: boolean;
	t?: boolean;
	r?: boolean;
	b?: boolean;
}

export const FadingCopyButton: FunctionComponent<FadingCopyButtonProps> = (props) => {
	const classes = useStyles();
	const [copied, setCopied] = useState<boolean>(false);
	const { show, title, message, children, l, t, r, b } = props;

	return (
		<Fade in={show} onExited={() => setCopied(false)}>
			<Tooltip
				title={
					copied ? (
						<Box display="flex" alignItems="center" alignContent="center">
							<Box>Skopírované</Box>
							<CheckCircleOutlineOutlinedIcon fontSize="inherit" className={classes.successCopyIcon} />
						</Box>
					) : (
						title
					)
				}
				TransitionProps={{ onExited: () => setCopied(false) }}
				placement="right"
			>
				<IconButton
					className={clsx(classes.absolute, {
						[classes.l]: l,
						[classes.t]: t,
						[classes.r]: r,
						[classes.b]: b,
					})}
					onClick={() => {
						navigator.clipboard.writeText(message);
						setCopied(true);
					}}
				>
					{children}
				</IconButton>
			</Tooltip>
		</Fade>
	);
};
