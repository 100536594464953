import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './ContentfulLoadingPlaceholder.styles';

export const ContentfulLoadingPlaceholder = () => {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h1">
				<Skeleton />
			</Typography>
			<Typography variant="h2">
				<Skeleton />
			</Typography>
			<Typography variant="body1">
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</Typography>
			<Typography variant="body1" className={classes.thirdW}>
				<Skeleton />
			</Typography>
			<Typography variant="h2">
				<Skeleton />
			</Typography>
			<Typography variant="body1">
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</Typography>
			<Typography variant="body1" className={classes.thirdW}>
				<Skeleton />
			</Typography>
			<Typography variant="h2">
				<Skeleton />
			</Typography>
			<Typography variant="body1">
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</Typography>
			<Typography variant="body1" className={classes.thirdW}>
				<Skeleton />
			</Typography>
		</>
	);
};
