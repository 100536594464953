import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	drawerPaper: {
		overflow: 'hidden',
		width: 240,
		backgroundColor: theme.palette.background.default,
		position: 'relative',
	},
}));
