import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
	title: {
		marginTop: 40,
		fontSize: '1.3rem',
	},
	icon: {
		fontSize: 300,
		'& path': {
			opacity: 0.15,
			transition: theme.transitions.create('opacity', {
				duration: 500,
				easing: theme.transitions.easing.easeOut,
			}),
			'&:hover': {
				opacity: 0.2,
			},
		},
	},
	chip: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
	},
	back: {
		marginRight: theme.spacing(0.5),
	},
}));
