import { withLayout } from '../../../withLayout';
import { useContentful } from 'react-contentful';
import { ContentfulLoadingPlaceholder, ContentfulLoadingError } from '../../../components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useRichTextOptions from '../../../useRichTextOptions';
import { BLOCKS } from '@contentful/rich-text-types';
import { List, ListItem } from '@material-ui/core';

const HistoriaUstavu = () => {
	const options = useRichTextOptions();
	const { data, error, fetched, loading } = useContentful({
		contentType: 'historia',
	});

	if (loading || !fetched) {
		return <ContentfulLoadingPlaceholder />;
	}

	if (error || !data) {
		if (error) console.error(error);
		return <ContentfulLoadingError />;
	}

	const {
		items: {
			0: {
				fields: { content },
			},
		},
	} = data;

	return documentToReactComponents(content, {
		...options,
		renderNode: {
			...options.renderNode,
			[BLOCKS.UL_LIST]: (node, children) => <List children={children} disablePadding />,
			[BLOCKS.LIST_ITEM]: (node, children) => <ListItem children={children} dense divider disableGutters />,
		},
	});
};

export default withLayout(HistoriaUstavu);
