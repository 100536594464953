export interface Contentful {
	loading: boolean;
	error?: string;
}

export enum ContentfulActions {
	CONTENTFUL_LOADING = 'CONTENTFUL_LOADING',
	CONTENTFUL_ERROR = 'CONTENTFUL_ERROR',
}

interface ContentfulActionType<T, P> {
	type: T;
	payload?: P;
}

export type ContentfulAction =
	| ContentfulActionType<typeof ContentfulActions.CONTENTFUL_LOADING, null>
	| ContentfulActionType<typeof ContentfulActions.CONTENTFUL_ERROR, string>;
