import { memo, forwardRef, ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function createSvgIcon(icon: ReactNode) {
	const Component = (props: SvgIconProps, ref: any) => (
		<SvgIcon ref={ref} {...props}>
			{icon}
		</SvgIcon>
	);

	return memo(forwardRef(Component));
}
