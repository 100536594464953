import Fotogaleria from './Fotogaleria';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import { pageSettings } from '../index';
import { FOTOGALERIA_PATH } from '../../constants/paths';

const settings: pageSettings = {
	path: FOTOGALERIA_PATH,
	name: 'Fotogaléria',
	icon: PhotoOutlinedIcon,
	component: Fotogaleria,
	contents: [],
};

export default settings;
