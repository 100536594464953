import { withLayout } from '../../../withLayout';
import useRichTextOptions from '../../../useRichTextOptions';
import { useContentful } from 'react-contentful';
import { ContentfulLoadingPlaceholder } from '../../../components';
import { ContentfulLoadingError } from '../../../components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const VedeckeProjekty = () => {
	const options = useRichTextOptions();
	const { data, error, fetched, loading } = useContentful({
		contentType: 'projekty',
	});

	if (loading || !fetched) {
		return <ContentfulLoadingPlaceholder />;
	}

	if (error || !data) {
		if (error) console.error(error);
		return <ContentfulLoadingError />;
	}

	const {
		items: {
			0: {
				fields: { content },
			},
		},
	} = data;

	return documentToReactComponents(content, options);
};

export default withLayout(VedeckeProjekty);
