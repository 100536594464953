import { Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { lightTheme, darkTheme } from './theme';
import { THEME, STORED_PREFERENCE } from './constants/theme';

export const withTheme = (Component: any) => {
	return (props: object) => {
		const prefersDarkModeSystemWide = useMediaQuery('(prefers-color-scheme: dark)');
		const [theme, setTheme] = useState<Theme>(lightTheme);

		useEffect(() => {
			const storedPreference = window.localStorage.getItem(STORED_PREFERENCE);
			if (storedPreference === null) {
				if (prefersDarkModeSystemWide) {
					setTheme(darkTheme);
					window.localStorage.setItem(STORED_PREFERENCE, THEME.DARK);
				} else {
					setTheme(lightTheme);
					window.localStorage.setItem(STORED_PREFERENCE, THEME.LIGHT);
				}
			} else {
				storedPreference === THEME.DARK ? setTheme(darkTheme) : setTheme(lightTheme);
			}
		}, [prefersDarkModeSystemWide]);

		const toggleTheme = () => {
			if (theme.palette.type === THEME.LIGHT) {
				setTheme(darkTheme);
				window.localStorage.setItem(STORED_PREFERENCE, THEME.DARK);
			} else {
				setTheme(lightTheme);
				window.localStorage.setItem(STORED_PREFERENCE, THEME.LIGHT);
			}
		};

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Component {...props} toggleTheme={toggleTheme} />
			</ThemeProvider>
		);
	};
};
