import { Container } from '@material-ui/core';
import { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Footer, ReactRouterBreadcrumbs, ScrollToTop } from './components';

export const withLayout = (Component: any) => {
	return (props: object) => {
		const classes = useStyles();
		const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
		const topAnchor = useRef<HTMLInputElement>(null);
		const triggerScrollTop = useScrollTrigger({
			disableHysteresis: true,
			threshold: 250,
			target: scrollTarget,
		});

		return (
			<div
				ref={(node) => {
					if (node) {
						setScrollTarget(node);
					}
				}}
				className={classes.content}
			>
				<Container maxWidth="md" component="main" className={classes.main}>
					<div ref={topAnchor} />
					<ReactRouterBreadcrumbs />
					<Container disableGutters>
						<Container disableGutters className={classes.slideTop}>
							<Component {...props} />
						</Container>
						<ScrollToTop visible={triggerScrollTop} topAnchor={topAnchor} />
					</Container>
				</Container>
				<Footer />
			</div>
		);
	};
};

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		backgroundColor: theme.palette.background.default,
		width: '100%',
		marginTop: theme.mixins.toolbar.minHeight,
		overflowX: 'hidden',
		overflowY: 'auto',
		[theme.breakpoints.down('xs')]: {
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		},
	},
	main: {
		paddingTop: theme.spacing(2),
	},
	footer: {
		marginTop: theme.spacing(8),
	},
	slideTop: {
		animation: `$slideTop ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut} both`,
	},
	'@keyframes slideTop': {
		'0%': {
			transform: 'translateY(15px)',
			opacity: 0,
		},
		'100%': {
			transform: 'translateY(0)',
			opacity: 1,
		},
	},
}));
