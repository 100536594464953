import { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import { withLayout } from '../../withLayout';
import { useContentful } from 'react-contentful';
import { ContentfulLoadingError, LoadableImage } from '../../components';
import { Aktualita } from '../../components';
import Carousel from 'react-material-ui-carousel';
import { useStyles } from './Aktuality.styles';

const AKTUALITY_BATCH_SIZE = 8;

const Aktuality = () => {
	const classes = useStyles();
	const [aktuality, setAktuality] = useState<Array<any>>([]);
	const [skip, setSkip] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const [initialized, setInitialized] = useState<boolean>(false);
	const { data, error, fetched, loading } = useContentful({
		contentType: 'aktuality',
		query: {
			order: '-fields.datum',
			limit: AKTUALITY_BATCH_SIZE,
			skip,
		},
	});
	const { data: carouselData } = useContentful({
		contentType: 'aktualityKarusel',
		query: {
			order: 'fields.poradie',
		},
	});

	const loadAktuality = useCallback(() => setSkip(aktuality.length), [aktuality]);

	useEffect(() => {
		if (!data?.items) return;
		setAktuality((prev) => [...prev, ...data?.items]);
		setInitialized(true);
		if (total === 0 && Number.isInteger(data?.total)) setTotal(data.total);
	}, [data]);

	const callbackScrollRef = useCallback((node) => {
		if (node !== null) node.scrollIntoView({ block: 'center' });
	}, []);

	if (error) {
		console.error(error);
		return <ContentfulLoadingError />;
	}

	return (
		<>
			<Carousel
				className={classes.carousel}
				interval={10000}
				indicators={(carouselData?.items?.length || 0) > 1}
				navButtonsAlwaysInvisible={(carouselData?.items?.length || 0) <= 1}
			>
				{carouselData?.items ? (
					carouselData.items
						?.map((item: any, index: number) => {
							if (!item?.fields?.fotografia?.fields?.file?.url) return null;
							return (
								<img
									key={item?.sys?.id || index}
									src={item.fields.fotografia.fields.file.url}
									alt={item.fields.alternativnyText}
									width="100%"
									height="400"
									className={classes.img}
								/>
							);
						})
						.filter(Boolean)
				) : (
					<LoadableImage src="" alt="placeholder" width="100%" height={400} />
				)}
			</Carousel>
			<Typography variant="h1">Aktuality</Typography>
			<Grid container spacing={3}>
				{(loading || !fetched) && !initialized ? (
					<>
						<Aktualita />
						<Aktualita />
						<Aktualita />
					</>
				) : (
					aktuality.map((item: any) => {
						const { nadpis, datum, obsah } = item.fields;
						return (
							<Aktualita
								title={nadpis}
								date={datum}
								content={obsah}
								id={item.sys.id}
								key={item.sys.id}
								ref={callbackScrollRef}
							/>
						);
					})
				)}
			</Grid>
			<Box display="flex" justifyContent="center" my={6}>
				{aktuality.length !== total && (
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={loadAktuality}
						className={classes.loadMoreButton}
					>
						Načítať viac
					</Button>
				)}
			</Box>
		</>
	);
};

export default withLayout(Aktuality);
