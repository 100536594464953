import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
	imgShadow: {
		boxShadow: theme.shadows[3],
	},
	hidden: {
		display: 'none',
	},
}));
