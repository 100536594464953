import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		overflow: 'auto',
		height: 'calc(100% - 48px)',
		marginTop: theme.spacing(6),
	},
	wrapperGrid: {
		paddingTop: theme.mixins.toolbar.minHeight,
		minHeight: '100%',
	},
	splashTop: {
		textAlign: 'center',
		margin: theme.spacing(6, 0, 12),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(6, 1),
		},
	},
	splashBottom: {
		backgroundColor: theme.palette.primary.main,
	},
	leftContainer: {
		backgroundColor: theme.palette.secondary.main,
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(-12),
			paddingLeft: theme.spacing(12),
			transform: 'skewX(-6deg)',
		},
	},
	rightContainer: {
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(-12),
			paddingLeft: theme.spacing(12),
		},
	},
	leftIcon: {
		color: theme.palette.secondary.dark,
		fontSize: theme.spacing(8),
	},
	rightIcon: {
		color: theme.palette.primary.dark,
		fontSize: theme.spacing(8),
	},
	darkSecondary: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.secondary.dark,
		transition: theme.transitions.create('background-color', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeIn,
		}),
		'&:hover': {
			backgroundColor: 'rgba(102,147,49,.5)',
		},
	},
	darkPrimary: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.dark,
		transition: theme.transitions.create('background-color', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeIn,
		}),
		'&:hover': {
			backgroundColor: 'rgba(46,128,154,.5)',
		},
	},
	unskew: {
		[theme.breakpoints.up('md')]: {
			transform: 'skewX(6deg)',
		},
	},
	content: {
		padding: theme.spacing(1),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(6),
			paddingTop: theme.spacing(4),
		},
	},
	splashText: {
		color: theme.palette.common.black,
		fontSize: '1.05rem',
		marginTop: theme.spacing(2),
	},
	kchTitle: {
		marginTop: 0,
		fontSize: '3rem',
		fontWeight: theme.typography.fontWeightMedium,
		letterSpacing: '.3rem',
		textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.75rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem',
		},
		[theme.breakpoints.down(410)]: {
			fontSize: '1.8rem',
			letterSpacing: '.2rem',
		},
	},
	kchSubtitle: {
		marginTop: 0,
		marginBottom: 0,
		fontWeight: theme.typography.fontWeightLight,
		[theme.breakpoints.down(410)]: {
			fontSize: '1.2rem',
		},
	},
	button: {
		marginTop: theme.spacing(3),
		minWidth: 300,
	},
	carousel: {
		marginBottom: theme.spacing(4),
	},
	img: {
		display: 'block',
		objectFit: 'cover',
	},
}));
