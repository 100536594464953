import { pageSettings } from '../../index';
import { PROJEKTY_PATH } from '../../../constants/paths';
import Projekty from './Projekty';

const settings: pageSettings = {
	path: PROJEKTY_PATH,
	name: 'Projekty',
	component: Projekty,
	contents: [],
};

export default settings;
