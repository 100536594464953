import { pageSettings } from '../../index';
import { BAKALARSKE_STUDIUM_PATH } from '../../../constants/paths';
import BakalarskeStudium from './BakalarskeStudium';

const settings: pageSettings = {
	path: BAKALARSKE_STUDIUM_PATH,
	name: 'Bakalárske štúdium',
	component: BakalarskeStudium,
	contents: [],
};

export default settings;
