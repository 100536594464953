import { ChangeEvent } from 'react';
import { withLayout } from '../../../withLayout';
import useRichTextOptions from '../../../useRichTextOptions';
import { useContentful } from 'react-contentful';
import { ContentfulLoadingPlaceholder } from '../../../components';
import { ContentfulLoadingError } from '../../../components';
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useStyles } from './PracovniciUstavu.styles';
import { ExpandMore } from '@material-ui/icons';
import { useState } from 'react';

const PracovniciUstavu = () => {
	const classes = useStyles();
	const options = useRichTextOptions();
	const [expanded, setExpanded] = useState<string | boolean>(false);
	const { data, error, fetched, loading } = useContentful({
		contentType: 'pracovniciKatedry',
		query: {
			order: 'fields.poradie',
		},
	});

	if (loading || !fetched) {
		return <ContentfulLoadingPlaceholder />;
	}

	if (error || !data?.items?.length) {
		if (error) console.error(error);
		return <ContentfulLoadingError />;
	}

	const { items } = data;

	const handleExpansion = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Typography variant="h1">Pracovníci ústavu</Typography>
			<Box>
				{items.map((item: any) => {
					const {
						fields: { informacie, podrobnosti },
					} = item;
					return (
						<Accordion key={item.sys.id} expanded={expanded === item.sys.id} onChange={handleExpansion(item.sys.id)}>
							<AccordionSummary classes={{ content: classes.summary }} expandIcon={<ExpandMore />}>
								{documentToReactComponents(informacie, options)}
							</AccordionSummary>
							<AccordionDetails classes={{ root: classes.details }}>
								{documentToReactComponents(podrobnosti, options)}
							</AccordionDetails>
						</Accordion>
					);
				})}
			</Box>
		</>
	);
};

export default withLayout(PracovniciUstavu);
