import HistoriaUstavu from './HistoriaUstavu';
import { pageSettings } from '../../index';
import { HISTORIA_USTAVU_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: HISTORIA_USTAVU_PATH,
	name: 'História ústavu',
	component: HistoriaUstavu,
	contents: [],
};

export default settings;
