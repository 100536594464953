import Aktuality from './Aktuality';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { pageSettings } from '../index';
import { AKTUALITY_PATH } from '../../constants/paths';

const settings: pageSettings = {
	path: AKTUALITY_PATH,
	name: 'Aktuality',
	icon: NotificationsOutlinedIcon,
	component: Aktuality,
	contents: [],
};

export default settings;
