import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	rootListItem: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		transition: theme.transitions.create('background-color', {
			duration: theme.transitions.duration.shorter,
			easing: theme.transitions.easing.easeOut,
		}),
	},
	hoveredDark: {
		backgroundColor: 'rgba(255,255,255,0)',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.1)',
		},
	},
	hoveredLight: {
		backgroundColor: 'rgba(0,0,0,0)',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
	padded: {
		paddingLeft: theme.spacing(3),
	},
	activeText: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.secondary.main,
	},
}));
