import App from './App';
import { HashRouter } from 'react-router-dom';
import { ContentfulClient, ContentfulClientInterface, ContentfulProvider } from 'react-contentful';
import { Provider } from 'react-redux';
import configureStore from './configureStore';

const contentfulClient: ContentfulClientInterface = ContentfulClient({
	accessToken: 'e2OYlNXoMAkq6rpZMP5AprW0SjrUWbIpQ2Xe2t-NaxA',
	space: '0xf63uy5ox76',
});

const store = configureStore();

export const ReduxRoot = () => {
	return (
		<Provider store={store}>
			<ContentfulProvider client={contentfulClient}>
				{/*<BrowserRouter basename="">*/}
				{/*	<App />*/}
				{/*</BrowserRouter>*/}
				<HashRouter>
					<App />
				</HashRouter>
			</ContentfulProvider>
		</Provider>
	);
};
