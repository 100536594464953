import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
	googleMap: {
		width: '100%',
		height: 500,
	},
	paperPadding: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1),
		},
	},
	paperHeight: {
		height: '100%',
	},
	noMarginTop: {
		marginTop: 0,
	},
}));
