import Partneri from './Partneri';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { pageSettings } from '../index';
import { PARTNERI_PATH } from '../../constants/paths';

const settings: pageSettings = {
	path: PARTNERI_PATH,
	name: 'Partneri',
	icon: PeopleAltOutlinedIcon,
	component: Partneri,
	contents: [],
};

export default settings;
