import { Drawer } from '@material-ui/core';
import { DrawerContent } from '../DrawerContent';
import { useStyles } from './NavigationMenuMobile.styles';

interface NavigationMenuMobileProps {
	toggleDrawer: () => void;
	open: boolean;
}

export const NavigationMenuMobile = ({ toggleDrawer, open }: NavigationMenuMobileProps) => {
	const classes = useStyles();

	return (
		<Drawer
			variant="temporary"
			anchor="left"
			open={open}
			classes={{
				paper: classes.drawerPaper,
			}}
			onClose={toggleDrawer}
			ModalProps={{
				keepMounted: true,
			}}
		>
			<DrawerContent toggleDrawer={toggleDrawer} />
		</Drawer>
	);
};
