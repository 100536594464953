import { ContentfulAction, ContentfulActions, Contentful } from '../model';
import createReducer from './createReducer';

export const contentful = createReducer<Contentful>(
	{ loading: false },
	{
		[ContentfulActions.CONTENTFUL_LOADING](state: Contentful, action: ContentfulAction) {
			return { loading: true };
		},
		[ContentfulActions.CONTENTFUL_ERROR](state: Contentful, action: ContentfulAction) {
			return { loading: false, error: action.payload };
		},
	}
);
