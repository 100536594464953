import { Link, useLocation } from 'react-router-dom';
import pages, { pageSettings } from '../../pages';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ROOT_PATH } from '../../constants/paths';

export const ReactRouterBreadcrumbs = () => {
	const theme = useTheme();
	const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
	const { pathname } = useLocation();
	const pathSplit = pathname.split('/').filter((val) => val);

	const resolvePage = (pages: pageSettings[], pathName: string): pageSettings | undefined => {
		let page = pages.find((p) => p.path === pathName);
		if (!page) {
			for (const p of pages) {
				if (p.subPages) {
					page = resolvePage(p.subPages, pathName);
					if (page) break;
				}
			}
		}
		return page;
	};

	const breadcrumbs: pageSettings[] = [];
	pathSplit.forEach((p, index) => {
		let path = '';
		for (let i = 0; i <= index; i++) {
			path += `/${pathSplit[i]}`;
		}
		const resolved = resolvePage(pages, path);
		breadcrumbs.push(resolved as pageSettings);
	});

	return (
		<Breadcrumbs maxItems={xsDown ? 3 : 8} separator="›">
			<Link color="inherit" to={ROOT_PATH}>
				Domov
			</Link>
			{breadcrumbs.map((page, index) => {
				const last = index === breadcrumbs.length - 1;
				return last ? (
					<Typography color="textPrimary" key={page.path}>
						{page.name}
					</Typography>
				) : page.component ? (
					<Link to={page.path} key={page.path}>
						{page.name}
					</Link>
				) : (
					<Typography key={page.path}>{page.name}</Typography>
				);
			})}
		</Breadcrumbs>
	);
};
