import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import { pageSettings } from '../index';
import { STUDIUM_PATH } from '../../constants/paths';
import BakalarskeStudium from './BakalarskeStudium';
import MagisterskeStudium from './MagisterskeStudium';
import DoktorandskeStudium from './DoktorandskeStudium';
import RigorozneStudium from './RigorozneStudium';
import ZahranicnePobyty from './ZahranicnePobyty';
import Studium from './Studium';

const settings: pageSettings = {
	path: STUDIUM_PATH,
	name: 'Štúdium',
	icon: SchoolOutlinedIcon,
	subPages: [BakalarskeStudium, MagisterskeStudium, DoktorandskeStudium, RigorozneStudium, ZahranicnePobyty],
	component: Studium,
	redirect: STUDIUM_PATH,
};

export default settings;
