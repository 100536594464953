import { forwardRef } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './Aktualita.styles';
import moment from 'moment';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useRichTextOptions from '../../useRichTextOptions';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import LinkIcon from '@material-ui/icons/Link';
import { FadingCopyButton } from '../FadingCopyButton';

interface AktualitaProps {
	title?: string;
	content?: any;
	date?: string;
	id?: string;
}

export const Aktualita = forwardRef<HTMLDivElement, AktualitaProps>(
	({ title, content, date, id }: AktualitaProps, forwardedScrollRef) => {
		const classes = useStyles();
		const options = useRichTextOptions();
		const { hash } = useLocation();

		return (
			<Grid item xs={12} ref={hash === `#${id}` ? forwardedScrollRef : null}>
				<Paper className={clsx({ [classes.highlighted]: hash === `#${id}` })}>
					<Grid container className={classes.container}>
						<Grid item xs={11}>
							<Typography variant="h5" className={clsx(classes.fullW, classes.title)}>
								{title ? title : <Skeleton />}
							</Typography>
						</Grid>
						<Grid item container xs={12}>
							{date ? (
								<Typography variant="body2" align="right">
									{moment(date).isValid() ? moment(date).format('DD.MM.YYYY, HH:mm') : date}
								</Typography>
							) : (
								<Typography variant="body1" className={classes.thirdW}>
									<Skeleton />
								</Typography>
							)}
						</Grid>
						{title && (
							<FadingCopyButton
								show={false}
								title="Skopírovať odkaz"
								message={window.location.origin + window.location.pathname + '#' + id}
								t
								r
							>
								<LinkIcon />
							</FadingCopyButton>
						)}
					</Grid>
					<Divider />
					<Box className={classes.container}>
						{content ? (
							documentToReactComponents(content, options)
						) : (
							<Typography variant="body1">
								<Skeleton />
								<Skeleton />
								<Skeleton />
							</Typography>
						)}
					</Box>
				</Paper>
			</Grid>
		);
	}
);
