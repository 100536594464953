import { Hidden, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';
import { withTheme } from './withTheme';
import { useState } from 'react';
import { AppBar, NavigationMenuMobile, NavigationMenu } from './components';
import { Routes } from './Routes';
import { ROOT_PATH } from './constants/paths';

interface AppProps {
	toggleTheme: () => void;
}

const App = (Props: AppProps) => {
	const classes = useStyles();
	const { toggleTheme } = Props;
	const { pathname } = useLocation();
	const [drawerMobileOpen, setDrawerMobileOpen] = useState<boolean>(false);

	const toggleDrawer = () => {
		setDrawerMobileOpen(!drawerMobileOpen);
	};

	return (
		<Box className={classes.root}>
			<AppBar toggleDrawer={toggleDrawer} toggleTheme={toggleTheme} />
			<Hidden smDown xsUp={pathname === ROOT_PATH}>
				<NavigationMenu />
			</Hidden>
			<Hidden mdUp={pathname !== ROOT_PATH}>
				<NavigationMenuMobile open={drawerMobileOpen} toggleDrawer={toggleDrawer} />
			</Hidden>
			<Routes />
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		height: '100vh',
		zIndex: 1,
		overflow: 'hidden',
		display: 'flex',
	},
}));

export default withTheme(App);
