import { createElement, useEffect, useState } from 'react';
import { Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useStyles } from './PrimaryNavItem.styles';
import { SvgIconComponent } from '@material-ui/icons';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { THEME } from '../../../constants/theme';

interface PrimaryNavItemProps {
	path: string;
	icon: SvgIconComponent;
	name: string;
	toggleDrawer?: () => void;
}

export const PrimaryNavItem = (Props: PrimaryNavItemProps) => {
	const theme = useTheme();
	const classes = useStyles();
	const { pathname } = useLocation();
	const [isItemActive, setIsItemActive] = useState<boolean>(false);
	const { path, icon, name, toggleDrawer } = Props;

	useEffect(() => {
		setIsItemActive(path === pathname);
	}, [path, pathname]);

	return (
		<>
			<Link to={path}>
				<ListItem
					button
					onClick={toggleDrawer}
					classes={{
						root: clsx(classes.rootListItem, {
							[classes.hoveredDark]: theme.palette.type === THEME.DARK,
							[classes.hoveredLight]: theme.palette.type === THEME.LIGHT,
						}),
					}}
				>
					<ListItemIcon classes={{ root: clsx({ [classes.activeIcon]: isItemActive }) }}>
						{createElement(icon)}
					</ListItemIcon>
					<ListItemText
						primary={name}
						primaryTypographyProps={{ classes: { root: clsx({ [classes.activeText]: isItemActive }) } }}
					/>
				</ListItem>
			</Link>
			<Divider />
		</>
	);
};
