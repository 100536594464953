import Projekty from './Projekty';
import PublikacnaCinnost from './PublikacnaCinnost';
import { ScienceOutlinedIcon } from '../../icons';
import { pageSettings } from '../index';
import { KVALITA_A_VEDA_PATH } from '../../constants/paths';
import VedeckeTimy from './VedeckeTimy';

const settings: pageSettings = {
	path: KVALITA_A_VEDA_PATH,
	name: 'Kvalita a veda',
	icon: ScienceOutlinedIcon,
	subPages: [Projekty, PublikacnaCinnost, VedeckeTimy],
};

export default settings;
