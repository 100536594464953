import PristrojoveVybavenie from './PristrojoveVybavenie';
import { pageSettings } from '../../index';
import { PRISTROJOVE_VYBAVENIE_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: PRISTROJOVE_VYBAVENIE_PATH,
	name: 'Prístrojové vybavenie',
	component: PristrojoveVybavenie,
	contents: [],
};

export default settings;
