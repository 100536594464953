import HistoriaUstavu from './HistoriaUstavu';
import Kontakt from './Kontakt';
import PracovniciUstavu from './PracovniciUstavu';
import PristrojoveVybavenie from './PristrojoveVybavenie';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { pageSettings } from '../index';
import { O_USTAVE_PATH } from '../../constants/paths';

const settings: pageSettings = {
	path: O_USTAVE_PATH,
	name: 'O ústave',
	icon: InfoOutlinedIcon,
	subPages: [HistoriaUstavu, PracovniciUstavu, PristrojoveVybavenie, Kontakt],
};

export default settings;
