export const ROOT_PATH = '/';

export const AKTUALITY_PATH = '/aktuality';

export const O_USTAVE_PATH = '/o-ustave';
export const HISTORIA_USTAVU_PATH = '/o-ustave/historia-ustavu';
export const KONTAKT_PATH = '/o-ustave/kontakt';
export const PRACOVNICI_USTAVU_PATH = '/o-ustave/pracovnici-ustavu';
export const PRISTROJOVE_VYBAVENIE_PATH = '/o-ustave/pristrojove-vybavenie';

export const STUDIUM_PATH = '/studium';
export const BAKALARSKE_STUDIUM_PATH = '/studium/bakalarske';
export const MAGISTERSKE_STUDIUM_PATH = '/studium/magisterske';
export const DOKTORANDSKE_STUDIUM_PATH = '/studium/doktorandske';
export const RIGOROZNE_STUDIUM_PATH = '/studium/rigorozne';
export const ZAHRANICNE_POBYTY_PATH = '/studium/zahranicne-pobyty';

export const KVALITA_A_VEDA_PATH = '/kvalita-a-veda';
export const ARCHIV_OBHAJENYCH_PRAC_PATH = '/kvalita-a-veda/archiv-obhajenych-prac';
export const OBHAJENE_BAKALARSKE_PRACE_PATH = '/kvalita-a-veda/archiv-obhajenych-prac/bakalarske-prace';
export const OBHAJENE_DIPLOMOVE_PRACE_PATH = '/kvalita-a-veda/archiv-obhajenych-prac/diplomove-prace';
export const OBHAJENE_DIZERTACNE_PRACE_PATH = '/kvalita-a-veda/archiv-obhajenych-prac/dizertacne-prace';
export const OBHAJENE_RIGOROZNE_PRACE_PATH = '/kvalita-a-veda/archiv-obhajenych-prac/rigorozne-prace';
export const PROJEKTY_PATH = '/kvalita-a-veda/projekty';
export const PUBLIKACNA_CINNOST_PATH = '/kvalita-a-veda/publikacna-cinnost';
export const VEDECKE_TIMY_PATH = '/kvalita-a-veda/vedecke-timy';

export const FOTOGALERIA_PATH = '/fotogaleria';

export const PARTNERI_PATH = '/partneri';
