import { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { Fade, Link, Tooltip } from '@material-ui/core';

interface AssetDownloadLinkProps {
	name: string;
	size: number;
	url: string;
	child: ReactNode;
}

export const AssetDownloadLink = ({ name, size, url, child }: AssetDownloadLinkProps) => {
	function formatBytes(a: number, b = 2) {
		if (0 === a) return '0 Bytes';
		const c = 0 > b ? 0 : b,
			d = Math.floor(Math.log(a) / Math.log(1024));
		return (
			parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
		);
	}

	return (
		<Tooltip
			placement="bottom"
			interactive
			TransitionComponent={Fade}
			title={
				<>
					<Typography variant="body2">{name}</Typography>
					<Typography variant="body2">{formatBytes(size, 1)}</Typography>
				</>
			}
		>
			<Link href={url}>{child}</Link>
		</Tooltip>
	);
};
