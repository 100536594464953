import Aktuality from './Aktuality';
import OUstave from './OUstave';
import Studium from './Studium';
import KvalitaAVeda from './KvalitaAVeda';
import UzitocneLinky from './UzitocneLinky';
import Fotogaleria from './Fotogaleria';
import { SvgIconComponent } from '@material-ui/icons';

export interface pageSettings {
	name: string;
	path: string;
	icon?: SvgIconComponent;
	component?: (props: object) => JSX.Element;
	contents?: string[];
	subPages?: pageSettings[];
	redirect?: string;
}

export default [Aktuality, OUstave, Studium, KvalitaAVeda, UzitocneLinky, Fotogaleria];
