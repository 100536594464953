import { pageSettings } from '../../index';
import { DOKTORANDSKE_STUDIUM_PATH } from '../../../constants/paths';
import DoktorandskeStudium from './DoktorandskeStudium';

const settings: pageSettings = {
	path: DOKTORANDSKE_STUDIUM_PATH,
	name: 'Doktorandské štúdium',
	component: DoktorandskeStudium,
	contents: [],
};

export default settings;
