import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { useStyles } from './Footer.styles';
import { ReactComponent as Logo } from '../../assets/kch_logo.svg';
import useRichTextOptions from '../../useRichTextOptions';
import { useContentful } from 'react-contentful';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Skeleton } from '@material-ui/lab';
import InstagramOutlined from '../../icons/InstagramOutlined';
import FacebookOutlined from '../../icons/FacebookOutlined';

export const Footer = () => {
	const classes = useStyles();
	const options = useRichTextOptions();
	const { data, fetched, loading } = useContentful({
		contentType: 'kontakt',
	});

	const optionsOverride: Options = {
		...options,
		renderNode: {
			...options.renderNode,
			[BLOCKS.HEADING_5]: (node, children) => (
				<Typography variant="h5" className={classes.noMarginTop}>
					{children}
				</Typography>
			),
		},
	};

	return (
		<Box component="footer" className={classes.footer}>
			<Container maxWidth="lg">
				<Box className={classes.content}>
					<Box>
						<Logo width={128} height={128} />
					</Box>
					<Box>
						{loading || !fetched ? (
							<>
								<Typography variant="h5" className={classes.noMarginTop}>
									<Skeleton />
								</Typography>
								<Typography variant="body1">
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</Typography>
							</>
						) : (
							documentToReactComponents(data?.items[0].fields.adresa, optionsOverride)
						)}
					</Box>
					<Box>
						{loading || !fetched ? (
							<>
								<Typography variant="h5" className={classes.noMarginTop}>
									<Skeleton />
								</Typography>
								<Typography variant="body1">
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</Typography>
							</>
						) : (
							documentToReactComponents(data?.items[0].fields.sidloKatedry, optionsOverride)
						)}
					</Box>
					<Box>
						<Link
							href="https://www.facebook.com/fpv.ucm.tt"
							target="_blank"
							rel="noopener noreferrer"
							className={classes.link}
						>
							<FacebookOutlined />
							<Typography variant="body1">Facebook</Typography>
						</Link>
						<Link
							href="https://www.instagram.com/katedra.chemie.ucm/"
							target="_blank"
							rel="noopener noreferrer"
							className={classes.link}
						>
							<InstagramOutlined />
							<Typography variant="body1">Instagram</Typography>
						</Link>
					</Box>
				</Box>
				<Typography variant="body2" align="center" className={classes.copyright}>
					Copyright © {new Date().getFullYear()} Univerzita sv. Cyrila a Metoda v Trnave. Všetky práva vyhradené.
				</Typography>
			</Container>
		</Box>
	);
};
