import ZahranicnePobyty from './ZahranicnePobyty';
import { pageSettings } from '../../index';
import { ZAHRANICNE_POBYTY_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: ZAHRANICNE_POBYTY_PATH,
	name: 'Zahraničné pobyty',
	component: ZahranicnePobyty,
	contents: [],
};

export default settings;
