import { createElement, useEffect, useState } from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { useStyles } from './PrimaryNavItemWithSubpages.styles';
import { SvgIconComponent } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { pageSettings } from '../../../pages';
import { NavItemWithSubpages } from '../NavItemWithSubpages';
import { NavItem } from '../NavItem';
import clsx from 'clsx';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useTheme } from '@material-ui/core/styles';
import { THEME } from '../../../constants/theme';

interface PrimaryNavItemWithSubpagesProps {
	icon: SvgIconComponent;
	name: string;
	path: string;
	subPages: pageSettings[];
	toggleDrawer?: () => void;
	redirect?: string;
}

export const PrimaryNavItemWithSubpages = (Props: PrimaryNavItemWithSubpagesProps) => {
	const theme = useTheme();
	const classes = useStyles();
	const { pathname } = useLocation();
	const history = useHistory();
	const [isItemActive, setIsItemActive] = useState<boolean>(false);
	const { icon, name, path, subPages, toggleDrawer, redirect } = Props;
	const [open, setOpen] = useState<boolean>(false);
	const [timeout, setTiming] = useState<TransitionProps['timeout'] | 'auto'>(0);

	useEffect(() => {
		const isCurrent = pathname.startsWith(path);
		setOpen(isCurrent);
		if (!isCurrent) setTiming('auto');
	}, [path, pathname]);

	useEffect(() => {
		const isCurrent = pathname.startsWith(path);
		setIsItemActive(isCurrent);
	}, [path, pathname]);

	return (
		<>
			<List disablePadding>
				<ListItem
					button
					onClick={() => {
						if (redirect) history.push(redirect);
						setOpen(!open);
					}}
					classes={{
						root: clsx(classes.rootListItem, {
							[classes.hoveredDark]: theme.palette.type === THEME.DARK,
							[classes.hoveredLight]: theme.palette.type === THEME.LIGHT,
						}),
					}}
				>
					<ListItemIcon classes={{ root: clsx({ [classes.activeIcon]: isItemActive }) }}>
						{createElement(icon)}
					</ListItemIcon>
					<ListItemText
						primary={name}
						primaryTypographyProps={{ classes: { root: clsx({ [classes.activeText]: isItemActive }) } }}
					/>
				</ListItem>
				<Collapse
					in={open}
					timeout={timeout}
					onEntered={() => {
						if (timeout === 0) setTiming('auto');
					}}
					className={classes.collapse}
				>
					{subPages.map((subPage) => {
						if (subPage.subPages) {
							return (
								<NavItemWithSubpages
									key={subPage.name}
									name={subPage.name}
									path={subPage.path}
									subPages={subPage.subPages}
									toggleDrawer={toggleDrawer}
									redirect={subPage.redirect}
								/>
							);
						} else if (subPage.component && subPage.path) {
							return <NavItem key={subPage.name} path={subPage.path} name={subPage.name} toggleDrawer={toggleDrawer} />;
						} else {
							return null;
						}
					})}
				</Collapse>
			</List>
			<Divider />
		</>
	);
};
