import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Box, Divider, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { AssetDownloadLink } from './components';
import { LoadableImage } from './components';

export const useStyles = makeStyles((theme: Theme) => ({
	imgWrapper: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	img: {
		boxShadow: theme.shadows[3],
	},
	divider: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
	},
}));

const useRichTextOptions = (): Options => {
	const classes = useStyles();

	return {
		renderNode: {
			[BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1">{children}</Typography>,
			[BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2">{children}</Typography>,
			[BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3">{children}</Typography>,
			[BLOCKS.HEADING_4]: (node, children) => <Typography variant="h4">{children}</Typography>,
			[BLOCKS.HEADING_5]: (node, children) => <Typography variant="h5">{children}</Typography>,
			[BLOCKS.HEADING_6]: (node, children) => <Typography variant="h6">{children}</Typography>,
			[BLOCKS.PARAGRAPH]: (node, children) => <Typography variant="body1">{children}</Typography>,
			[INLINES.HYPERLINK]: (node, children) => {
				const {
					data: { uri },
				} = node;
				return (
					<Link href={uri} target="_blank" rel="noopener noreferrer">
						{children}
					</Link>
				);
			},
			[INLINES.ASSET_HYPERLINK]: (node, children) => {
				const {
					data: {
						target: {
							fields: {
								file: {
									url,
									fileName,
									details: { size },
								},
							},
						},
					},
				} = node;
				return <AssetDownloadLink name={fileName} size={size} url={url} child={children} />;
			},
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const {
					data: {
						target: {
							fields: {
								file: {
									url,
									details: {
										image: { width, height },
									},
								},
								title,
							},
						},
					},
				} = node;
				return (
					<Box display="flex" justifyContent="center" className={classes.imgWrapper}>
						<LoadableImage src={url} alt={title} width={width} height={height} shadow />
					</Box>
				);
			},
			[BLOCKS.HR]: () => <Divider className={classes.divider} />,
		},
	};
};

export default useRichTextOptions;
