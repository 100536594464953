import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import pages from '../../pages';
import { PrimaryNavItem } from './PrimaryNavItem';
import { PrimaryNavItemWithSubpages } from './PrimaryNavItemWithSubpages';
import { ChevronLeft } from '@material-ui/icons';
import kch_logo from '../../assets/kch_logo.png';
import { useStyles } from './DrawerContent.styles';
import { ContentCopyOutlinedIcon } from '../../icons';
import { LoadableImage } from '../LoadableImage';
import { FadingCopyButton } from '../FadingCopyButton';

interface DrawerContentProps {
	toggleDrawer?: () => void;
}

export const DrawerContent = ({ toggleDrawer }: DrawerContentProps) => {
	const classes = useStyles();

	const renderDrawerNav = () => {
		return (
			<Box component="nav">
				{pages.map((page) => {
					if (page.subPages && page.icon) {
						return (
							<PrimaryNavItemWithSubpages
								key={page.name}
								icon={page.icon}
								name={page.name}
								path={page.path}
								subPages={page.subPages}
								toggleDrawer={toggleDrawer}
								redirect={page.redirect}
							/>
						);
					} else if (page.component && page.icon) {
						return (
							<PrimaryNavItem
								key={page.name}
								icon={page.icon}
								name={page.name}
								path={page.path}
								toggleDrawer={toggleDrawer}
							/>
						);
					} else {
						return null;
					}
				})}
			</Box>
		);
	};

	return (
		<>
			<Box className={classes.drawerHeader} onClick={toggleDrawer}>
				<IconButton onClick={toggleDrawer} disableRipple>
					<ChevronLeft />
				</IconButton>
			</Box>
			<Divider />
			<Box className={classes.drawerMain}>
				{renderDrawerNav()}
				{/*<Grid container justifyContent="center">*/}
				{/*	<Grid item className={classes.drawerAddress}>*/}
				{/*		<Typography variant="body2">Univerzita sv. Cyrila a Metoda v Trnave</Typography>*/}
				{/*		<Typography variant="body2">Fakulta prírodných vied</Typography>*/}
				{/*		<Typography variant="body2">Ústav chémie a environmentálnych vied</Typography>*/}
				{/*		<Typography variant="body2">Nám. J. herdu 2</Typography>*/}
				{/*		<Typography variant="body2">917 01 Trnava</Typography>*/}
				{/*		<FadingCopyButton*/}
				{/*			show={false}*/}
				{/*			title="Skopírovať adresu"*/}
				{/*			message="Univerzita sv. Cyrila a Metoda v Trnave, Fakulta prírodných vied, Ústav chémie a environmentálnych vied, Nám. J. herdu 2, 917 01 Trnava"*/}
				{/*			r*/}
				{/*			b*/}
				{/*		>*/}
				{/*			<ContentCopyOutlinedIcon />*/}
				{/*		</FadingCopyButton>*/}
				{/*	</Grid>*/}
				{/*	<Grid item className={classes.logo}>*/}
				{/*		<LoadableImage src={kch_logo} alt="Ústav chémie a environmentálnych vied" width={96} />*/}
				{/*	</Grid>*/}
				{/*</Grid>*/}
			</Box>
		</>
	);
};
