import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	carousel: {
		margin: theme.spacing(1, 0, 2),
	},
	img: {
		display: 'block',
		objectFit: 'cover',
	},
	loadMoreButton: {
		maxWidth: 240,
		width: '100%',
	},
}));
