import { pageSettings } from '../../index';
import { MAGISTERSKE_STUDIUM_PATH } from '../../../constants/paths';
import MagisterskeStudium from './MagisterskeStudium';

const settings: pageSettings = {
	path: MAGISTERSKE_STUDIUM_PATH,
	name: 'Magisterské štúdium',
	component: MagisterskeStudium,
	contents: [],
};

export default settings;
