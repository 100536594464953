import PracovniciUstavu from './PracovniciUstavu';
import { pageSettings } from '../../index';
import { PRACOVNICI_USTAVU_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: PRACOVNICI_USTAVU_PATH,
	name: 'Pracovníci ústavu',
	component: PracovniciUstavu,
	contents: [],
};

export default settings;
