import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	rootListItem: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
		},
		transition: theme.transitions.create('background-color', {
			duration: theme.transitions.duration.short,
			easing: theme.transitions.easing.easeOut,
		}),
	},
	hoveredDark: {
		backgroundColor: 'rgba(255,255,255,0)',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.1)',
		},
	},
	hoveredLight: {
		backgroundColor: 'rgba(0,0,0,0)',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
	activeIcon: {
		color: theme.palette.secondary.main,
	},
	activeText: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.secondary.main,
	},
}));
