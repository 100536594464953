import Kontakt from './Kontakt';
import { pageSettings } from '../../index';
import { KONTAKT_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: KONTAKT_PATH,
	name: 'Kontakt',
	component: Kontakt,
	contents: [],
};

export default settings;
