import { Typography, Paper, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withLayout } from '../../../withLayout';
import { useStyles } from './Kontakt.styles';
import clsx from 'clsx';
import { useContentful } from 'react-contentful';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import useRichTextOptions from '../../../useRichTextOptions';
import { ContentfulLoadingError } from '../../../components';
import { BLOCKS } from '@contentful/rich-text-types';

const Kontakt = () => {
	const classes = useStyles();
	const options = useRichTextOptions();
	const { data, error, fetched, loading } = useContentful({
		contentType: 'kontakt',
	});

	const optionsOverride: Options = {
		...options,
		renderNode: {
			...options.renderNode,
			[BLOCKS.HEADING_5]: (node, children) => (
				<Typography variant="h5" className={classes.noMarginTop}>
					{children}
				</Typography>
			),
		},
	};

	if (error) {
		if (error) console.error(error);
		return <ContentfulLoadingError />;
	}

	return (
		<>
			<Typography variant="h1">Kontakt</Typography>
			<Grid container spacing={2} alignItems="stretch">
				<Grid item xs={12} sm={6}>
					<Paper className={clsx(classes.paperPadding, classes.paperHeight)}>
						{loading || !fetched ? (
							<>
								<Typography variant="h5" className={classes.noMarginTop}>
									<Skeleton />
								</Typography>
								<Typography variant="body1">
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</Typography>
							</>
						) : (
							documentToReactComponents(data?.items[0].fields.adresa, optionsOverride)
						)}
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={clsx(classes.paperPadding, classes.paperHeight)}>
						{loading || !fetched ? (
							<>
								<Typography variant="h5" className={classes.noMarginTop}>
									<Skeleton />
								</Typography>
								<Typography variant="body1">
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</Typography>
							</>
						) : (
							documentToReactComponents(data?.items[0].fields.sidloKatedry, optionsOverride)
						)}
					</Paper>
				</Grid>
				<Grid item xs>
					<Paper className={clsx(classes.paperPadding, classes.googleMap)}>
						{loading || !fetched ? (
							<Skeleton variant="rect" height="100%" width="100%" />
						) : (
							<iframe
								title="Sídlo ústavu na Google Maps"
								width="100%"
								height="100%"
								id="gmap_canvas"
								src={data?.items[0].fields.mapaUrl}
								frameBorder="0"
								scrolling="no"
								marginHeight={0}
								marginWidth={0}
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default withLayout(Kontakt);
