import { pageSettings } from '../../index';
import { RIGOROZNE_STUDIUM_PATH } from '../../../constants/paths';
import RigorozneStudium from './RigorozneStudium';

const settings: pageSettings = {
	path: RIGOROZNE_STUDIUM_PATH,
	name: 'Rigorózne štúdium',
	component: RigorozneStudium,
	contents: [],
};

export default settings;
