import { alpha, makeStyles } from '@material-ui/core';
import { THEME } from '../../constants/theme';

export const useStyles = makeStyles((theme) => ({
	footer: {
		backgroundColor: theme.palette.navigation.background,
		marginTop: theme.spacing(10),
		padding: theme.spacing(6, 0),
		borderTop: `0.5px solid ${alpha(
			theme.palette.type === THEME.DARK ? theme.palette.common.white : theme.palette.common.black,
			0.12
		)}`,
		[theme.breakpoints.only('xs')]: {
			padding: theme.spacing(2, 0, 6),
		},
	},
	copyright: {
		marginTop: theme.spacing(6),
	},
	noMarginTop: {
		marginTop: 0,
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		'& > *:not(:last-child)': {
			marginRight: theme.spacing(4),
		},
		[theme.breakpoints.only('xs')]: {
			flexDirection: 'column',
			'& > *:not(:last-child)': {
				marginRight: 0,
				marginBottom: theme.spacing(2),
			},
		},
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		'& > *:not(:last-child)': {
			marginRight: theme.spacing(1),
		},
		'&:not(:last-child)': {
			marginBottom: theme.spacing(1),
		},
	},
}));
