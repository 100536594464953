import { Button, Typography, Grid, Box } from '@material-ui/core';
import { useStyles } from './Domov.styles';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AKTUALITY_PATH, STUDIUM_PATH, PROJEKTY_PATH } from '../../constants/paths';
import clsx from 'clsx';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import { ScienceOutlinedIcon } from '../../icons';
import Carousel from 'react-material-ui-carousel';
import { useContentful } from 'react-contentful';
import { Footer, LoadableImage } from '../../components';

const Domov = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));
	const { data } = useContentful({
		contentType: 'uvodnyKarusel',
		query: {
			order: 'fields.poradie',
		},
	});

	return (
		<Box className={classes.root}>
			<Carousel
				className={classes.carousel}
				interval={10000}
				indicators={(data?.items?.length || 0) > 1}
				navButtonsAlwaysInvisible={(data?.items?.length || 0) <= 1}
			>
				{data?.items ? (
					data.items
						?.map((item: any, index: number) => {
							if (!item?.fields?.fotografia?.fields?.file?.url) return null;
							return (
								<img
									key={item?.sys?.id || index}
									src={item.fields.fotografia.fields.file.url}
									alt={item.fields.alternativnyText}
									width="100%"
									height="400"
									className={classes.img}
								/>
							);
						})
						.filter(Boolean)
				) : (
					<LoadableImage src="" alt="placeholder" width="100%" height={400} />
				)}
			</Carousel>
			<Box className={classes.splashTop}>
				<Typography className={classes.kchTitle} variant="h1" gutterBottom>
					Ústav chémie a environmentálnych vied
				</Typography>
				<Typography className={classes.kchSubtitle} variant="h2" gutterBottom>
					Fakulta prírodných vied
				</Typography>
				<Typography className={classes.kchSubtitle} variant="h2" gutterBottom>
					Univerzita sv. Cyrila a Metoda v Trnave
				</Typography>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					onClick={() => history.push(AKTUALITY_PATH)}
					classes={{
						root: classes.button,
					}}
				>
					Vstúpiť
				</Button>
			</Box>
			<Grid container direction={smDown ? 'column' : 'row'} className={classes.splashBottom}>
				<Grid item container xs className={classes.leftContainer} justifyContent="center">
					<Grid
						item
						container
						justifyContent="space-between"
						direction="column"
						alignItems="center"
						xs={12}
						sm={10}
						className={clsx(classes.content, classes.unskew)}
					>
						<Box display="flex" flexDirection="column" alignItems="center">
							<SchoolOutlinedIcon className={classes.leftIcon} />
							<Typography variant={'body1'} align="center" className={classes.splashText}>
								Chceš u nás študovať? Všetky dôležité informácie o bakalárskom, magisterskom a doktorandskom štúdiu
								nájdeš v sekcii Štúdium.
							</Typography>
						</Box>
						<Button
							variant="contained"
							size="large"
							onClick={() => history.push(STUDIUM_PATH)}
							classes={{ contained: classes.darkSecondary }}
							className={classes.button}
						>
							Pokračovať na Štúdium
						</Button>
					</Grid>
				</Grid>
				<Grid item container xs className={classes.rightContainer} justifyContent="center">
					<Grid
						item
						container
						justifyContent="space-between"
						direction="column"
						alignItems="center"
						xs={12}
						sm={10}
						className={classes.content}
					>
						<Box display="flex" flexDirection="column" alignItems="center">
							<ScienceOutlinedIcon className={classes.rightIcon} />
							<Typography variant={'body1'} align="center" className={classes.splashText}>
								Pozrite si, ako sa ústav angažuje v oblasti kvality a vedy. Prečítajte si viac o publikačnej činnosti a
								projektoch, alebo navštívte archív obhájených prác.
							</Typography>
						</Box>
						<Button
							variant="contained"
							size="large"
							onClick={() => history.push(PROJEKTY_PATH)}
							classes={{ contained: classes.darkPrimary }}
							className={classes.button}
						>
							Pokračovať na Kvalitu a vedu
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Footer />
		</Box>
	);
};

export default Domov;
