import { ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './NavItem.styles';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { THEME } from '../../../constants/theme';
import { useTheme } from '@material-ui/core/styles';

interface NavItemProps {
	path: string;
	name: string;
	toggleDrawer?: () => void;
	padded?: boolean;
}

export const NavItem = ({ path, name, toggleDrawer, padded }: NavItemProps) => {
	const theme = useTheme();
	const { pathname } = useLocation();
	const [isItemActive, setIsItemActive] = useState<boolean>(false);
	const classes = useStyles();

	useEffect(() => {
		setIsItemActive(path === pathname);
	}, [path, pathname]);

	return (
		<Link to={path}>
			<ListItem
				button
				onClick={toggleDrawer}
				classes={{
					root: clsx(classes.rootListItem, {
						[classes.hoveredDark]: theme.palette.type === THEME.DARK,
						[classes.hoveredLight]: theme.palette.type === THEME.LIGHT,
						[classes.padded]: padded,
					}),
				}}
			>
				<ListItemText
					primary={name}
					primaryTypographyProps={{
						classes: {
							root: clsx({
								[classes.activeText]: isItemActive,
							}),
						},
					}}
				/>
			</ListItem>
		</Link>
	);
};
