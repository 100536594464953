import { useStyles } from './ContentfulLoadingError.styles';
import { Grid, Typography } from '@material-ui/core';

export const ContentfulLoadingError = () => {
	const classes = useStyles();

	return (
		<Grid container item justifyContent="center" className={classes.error}>
			<Typography variant="h6">Nepodarilo sa načítať obsah. Prosím, skúste to znovu.</Typography>
		</Grid>
	);
};
