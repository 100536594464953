import { Drawer } from '@material-ui/core';
import { DrawerContent } from '../DrawerContent';
import { useStyles } from './NavigationMenu.styles';

export const NavigationMenu = () => {
	const classes = useStyles();

	return (
		<Drawer
			variant="permanent"
			open
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<DrawerContent />
		</Drawer>
	);
};
