import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	drawerHeader: {
		height: theme.spacing(6) - 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	drawerMain: {
		overflowX: 'hidden',
		overflowY: 'auto',
		[theme.breakpoints.down('xs')]: {
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		},
		'&::-webkit-scrollbar': {
			width: 7,
		},
		'&::-webkit-scrollbar-track': {
			background: 'rgba(0,0,0,0.1)',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 2,
			backgroundColor: 'rgba(158,158,158,0.4)',
		},
		'&::-webkit-scrollbar-thumb:vertical:hover': {
			backgroundColor: 'rgba(158,158,158,0.6)',
		},
	},
	drawerAddress: {
		padding: theme.spacing(2),
		position: 'relative',
	},
	rootNavItem: {
		padding: theme.spacing(2),
	},
	copyToClipboard: {
		position: 'absolute',
		right: 6,
		bottom: 6,
	},
	logo: {
		userSelect: 'none',
		marginBottom: theme.spacing(2),
	},
	successCopyIcon: {
		color: theme.palette.success.main,
		marginLeft: theme.spacing(0.5),
	},
}));
