import VedeckeTimy from './VedeckeTimy';
import { pageSettings } from '../../index';
import { VEDECKE_TIMY_PATH } from '../../../constants/paths';

const settings: pageSettings = {
	path: VEDECKE_TIMY_PATH,
	name: 'Vedecké tímy',
	component: VedeckeTimy,
	contents: [],
};

export default settings;
